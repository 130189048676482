import {
  ForgotPasswordInputType,
  ForgotPasswordInputTypeDefaultValues,
  ForgotPasswordInputTypeValidationBuilder,
} from '@bas/authentication-domain/input-types';
import { useForgotPasswordMutation } from '@bas/authentication-domain/mutations';
import { ForgotPasswordForm } from '@bas/authentication-domain/native/organisms';
import { useAppStore } from '@bas/shared/state';
import { colors } from '@bas/theme';
import { Button, ReactHookForm } from '@bas/ui/native/atoms';
import { Icon, Typography } from '@bas/ui/native/base';
import { useSetEnvironment } from '@bas/ui/native/hooks';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { router } from 'expo-router';
import { StatusBar } from 'expo-status-bar';
import { ReactElement, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.blue[900],
    flex: 1,
    alignItems: 'center',
  },
  keyboardContainer: {
    flex: 1,
    maxWidth: 390,
    width: '100%',
  },
  requestedContainer: {
    display: 'flex',
  },
  requestedIcon: {
    marginBottom: 16,
    color: colors.lila[200],
    alignSelf: 'center',
  },
  requestedText: {
    color: colors.lila[200],
  },
  formContainer: {
    width: '100%',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '100%',
    maxWidth: 390,
    flexDirection: 'column',
  },
  title: {
    marginBottom: 71,
    color: colors.white,
  },
  backText: {
    color: colors.lila[200],
    textTransform: 'lowercase',
  },
  backIcon: { marginRight: 5 },
  formEndContainer: {
    paddingTop: 35,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxHeight: 85,
  },
  backContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

const ForgotPasswordScreen = (): ReactElement => {
  const [requested, setRequested] = useState(false);
  const { mutateAsync: forgotPasswordMutation } = useForgotPasswordMutation();
  const appUrl = useAppStore((state) => state.appUrl);
  const setEnvironment = useSetEnvironment();

  const handleForgotPassword = useCallback(
    async (values: ForgotPasswordInputType) => {
      setEnvironment(values.appUrl);

      await forgotPasswordMutation(values);

      setRequested(true);
    },
    [forgotPasswordMutation, setEnvironment],
  );

  return (
    <ScrollView
      contentContainerStyle={{ flex: 1 }}
      style={{
        backgroundColor: colors.blue[900],
      }}
    >
      <KeyboardAvoidingView style={{ flex: 1 }} behavior="height">
        <SafeAreaView style={styles.container}>
          {/* eslint-disable-next-line react/style-prop-object */}
          <StatusBar style="light" />
          <View style={styles.formContainer}>
            <Typography style={styles.title} fontWeight={700} variant="h3">
              <FormattedMessage id="login.forgotPassword" />
            </Typography>

            {requested && (
              <View style={styles.requestedContainer}>
                <Icon
                  icon={faEnvelope}
                  size={64}
                  style={styles.requestedIcon}
                />
                <Typography style={styles.requestedText}>
                  <FormattedMessage id="label.passwordResetRequested" />
                </Typography>
                <View style={styles.formEndContainer}>
                  <Button
                    variant="text"
                    onPress={() => router.navigate('/login')}
                    style={styles.backContainer}
                  >
                    <Icon
                      overrideColor={colors.lila[200]}
                      style={styles.backIcon}
                      size={12}
                      icon={faArrowLeft}
                    />
                    <Typography style={styles.backText}>
                      <FormattedMessage id="button.back" />
                    </Typography>
                  </Button>
                </View>
              </View>
            )}
            {!requested && (
              <ReactHookForm<
                ForgotPasswordInputType & { backendErrors: unknown }
              >
                style={styles.form}
                onSubmit={handleForgotPassword}
                useProvider
                defaultValues={{
                  ...ForgotPasswordInputTypeDefaultValues(),
                  appUrl:
                    Platform.OS === 'web'
                      ? window.location.hostname.replace('.bas.app', '')
                      : appUrl.replace('.bas.app', ''),
                }}
                validationSchema={ForgotPasswordInputTypeValidationBuilder}
              >
                <ForgotPasswordForm />
              </ReactHookForm>
            )}
          </View>
        </SafeAreaView>
      </KeyboardAvoidingView>
    </ScrollView>
  );
};

export default ForgotPasswordScreen;
