import { EmailAddress, ErrorResponse } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ForgotPasswordMutationProps = {
  emailAddress: EmailAddress;
};

export const ForgotPasswordMutation = async ({
  emailAddress,
  ...params
}: ForgotPasswordMutationProps): Promise<AxiosResponse> =>
  axios.post('/api/users/forgot-password', { emailAddress }, { params });

export const useForgotPasswordMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ForgotPasswordMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ForgotPasswordMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ForgotPasswordMutationProps
  >({
    mutationFn: ForgotPasswordMutation,
    ...options,
    throwOnError: (error) => (error?.response?.status || 500) >= 500,
  });
